import React from "react"
import { Button, LegacyStack, Text } from "@shopify/polaris";
import styled from 'styled-components'
import formatCurrency from "../../../../helpers/formatCurrency"
import Hr from "./separator"
const OrderSummaryWrapper = styled.div`
    padding: 30px;
    .upsell-pp-price {
            padding: 10px 0;
            display: flex;
            flex-direction: column;
            gap: 7px;
          }
          .upsell-pp-price-total {
            p {
              float: right;
            }
          }
          .upsell-pp-buttons {
            clear: both;
            display: flex;
            padding-top: 10px;
            flex-direction: column;
            gap: 5px;
            button:first-of-type {
              height: 57px;
              background: rgb(38,181,34);
              color: rgb(213,254,212);
              border: none;
            }
        }
`
function OrderSummaryPreview(props) {
  const { product, currencySymbol, locale, currency, offerIndex } = props
  const subTotal = product[offerIndex].products.reduce((a, b) => {
    if (b.discount.value > 0) {
      if (b.discount.sym === currencySymbol) {
        return (
          a +
          (parseFloat(b.variants && b?.variants[0]?.price ? b.variants[0].price : 0) - b.discount.value)
        )
      } else {
        return (
          a +
          parseFloat(b.variants && b?.variants[0]?.price ? b.variants[0].price : 0) *
            (1 - b.discount.value / 100)
        )
      }
    } else {
      return a + parseFloat(b.variants && b?.variants[0]?.price ? b.variants[0].price : 0)
    }
  }, 0)
  const savings = product[offerIndex].products.reduce((a, b) => {
    if (b.discount.value > 0) {
      if (b.discount.sym === currencySymbol) {
        return a + b.discount.value
      } else {
        return (
          a +
          parseFloat(b.variants && b?.variants[0]?.price ? b.variants[0].price : 0) *
            (b.discount.value / 100)
        )
      }
    } else {
      return a
    }
  }, 0)
  return (
    <OrderSummaryWrapper>
      <Hr />
      <div className="upsell-pp-price">
        <LegacyStack>
          <LegacyStack.Item fill>{product[offerIndex].subtotalText ? product[offerIndex].subtotalText : "SubTotal"}</LegacyStack.Item>
          <LegacyStack.Item>
            {`${formatCurrency(subTotal, locale, currency)}`}
          </LegacyStack.Item>
        </LegacyStack>
        {savings > 0 ? (
          <LegacyStack>
            <LegacyStack.Item fill>
              <span style={{ color: "#0CA127" }}>{product[offerIndex].savingText ? product[offerIndex].savingText : "Savings"}</span>
            </LegacyStack.Item>
            <LegacyStack.Item>
              <span style={{ color: "#0CA127" }}>
                {formatCurrency(savings, locale, currency)}
              </span>
            </LegacyStack.Item>
          </LegacyStack>
        ) : (
          false
        )}

        <LegacyStack>
          <LegacyStack.Item fill>
            {product[offerIndex].shippingText ? product[offerIndex].shippingText : "Shipping"}
          </LegacyStack.Item>
          <LegacyStack.Item>{formatCurrency(0, locale, currency)}</LegacyStack.Item>
        </LegacyStack>
      </div>
      <Hr />
      <div className="upsell-pp-price-total">
        <Text variant="headingXl" as="p">
          {`${formatCurrency(subTotal, locale, currency)}`}
        </Text>
      </div>
      <div className="upsell-pp-buttons">
        <Button>
        {product[offerIndex].acceptButtonText ? product[offerIndex].acceptButtonText : "Pay now"} • {`${formatCurrency(subTotal, locale, currency)}`}
        </Button>
        <Button  variant="plain">{product[offerIndex].declineButtonText ? product[offerIndex].declineButtonText : "Decline this offer"}</Button>
      </div>
    </OrderSummaryWrapper>
  );
}
export default OrderSummaryPreview
